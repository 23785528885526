<div class="content-wrapper container">
    <div class="card card-default">
      
  
      <div class="card-body" *ngIf="isActive == 0">
        <h4>Franchise List</h4>
  
        <button
          class="btn btn-primary m-4 pull-right"
          (click)="addFran(Franch); isMainOfferView = false"
        >
          Add Franchise
        </button>
  
        <table class="table table-bordered my-table">
          <thead>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Location</th>
            <th>Address</th>
            <th>Status</th>
            <th>Edit</th>
          </thead>
  
          <tbody *ngFor="let fran of franchiselist">
            <tr>
              <td>{{fran.first_name}}</td>
              <td>{{fran.email}}</td>
              <td>{{fran.mobile}}</td>
              <td>{{fran.sevice_location.name}}</td>
              <td>{{fran.address}}</td>
              <td>{{fran.is_active==1? "Active": "In Active"}}</td>
            
            <td> <button class="btn btn-primary btn-sm" (click)="isEdit(fran,index,Franch)">Edit</button></td>
            </tr>
            
          </tbody>
        </table>
      </div>
  
      
    </div>
  
    
  </div>
  

  
  <ng-template #Franch>
    <div class="modal-header">Add/Edit Franchise</div>
    <div class="modal-body">
      <div class="">
        <div class="form-group row">
          <div class="col-md-12">
            <div class="row register-form">
                <!--  -->
              <!-- <div class="col-md-6"> -->
                <input type="hidden" [(ngModel)]="franchise_id">
                <div class="form-group text-left col-md-6">
                  <label>Name</label>
                  <input
                    type="text"
                    [(ngModel)]="name"
                    class="form-control"
                    placeholder="Franchise Name *"
                  />
                </div>
  
                <div class="form-group text-left col-md-6">
                  <label>Email</label>
                  <input
                  type="text"
                  [(ngModel)]="email"
                  class="form-control"
                  placeholder="Email*"
                />
                </div>
                <div class="form-group text-left col-md-6">
                    <label>Mobile</label>
                    <input
                    type="text"
                    [(ngModel)]="mobile"
                    class="form-control"
                    placeholder="Mobile*"
                  />
                </div>
                <div class="form-group text-left col-md-6">
                  <label>Location</label>
                  
                <select
                    class="form-control"
                    [(ngModel)]="location"
                  >
                  <option class="hidden" selected="" disabled="">
                    Select Location
                  </option>
                  <option [value]="area.id" *ngFor="let area of deliveryArea">
                    {{ area.name }}
                  </option>
                  </select>
              </div>
              <div class="form-group text-left col-md-6">
                <label>Address</label>
                <input
                type="text"
                [(ngModel)]="address"
                class="form-control"
                placeholder="address*"
              />
            </div>
            <div class="form-group text-left col-md-6">
              <label>Status</label>
              
            <select class="form-control" [(ngModel)]="status" placeholder="Select status">
              <option value="1">Active</option>
              <option value="2">In Active</option>
            </select>
          </div>
          <!--<div class="form-group text-left col-md-6">
            <label>Latitude</label>
            <input
            type="text"
            [(ngModel)]="lat"
            class="form-control"
            placeholder="Latitude*"
          />
        </div>
        <div class="form-group text-left col-md-6">
          <label>Longitude</label>
          <input
          type="text"
          [(ngModel)]="lng"
          class="form-control"
          placeholder="Longitude*"
        />
      </div>-->
              <!-- </div> -->
              <!--  -->
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="modal-footer">
      <button
        class="btn btn-success"
        (click)="createFranchise()"
        [disabled]="!name || !email || !location || !mobile || !address"
      >
        <fa-icon [icon]="faEdit"></fa-icon> {{franchise_id==0? "Create": "Update"}} Franchise
      </button>
  
      <button
        type="button"
        *ngIf="isMainOfferView"
        class="btn btn-success"
        (click)="updateFranchise()"
      >
        <fa-icon [icon]="faEdit"></fa-icon> Update Main Offer
      </button>
  
      <button type="button" class="btn btn-warning" (click)="decline()">
        <fa-icon [icon]="faTimes"></fa-icon> Cancel
      </button>
    </div>
  </ng-template>
  
    
  <div class="loader-backdrop" *ngIf="isLoading">
    <div class="loader"></div>
    <h4>Loading...</h4>
  </div>
  