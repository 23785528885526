import { Component, OnInit, TemplateRef } from '@angular/core';

import { ApiUserProvider } from '../../providers/api-user/api-user';
import { Router } from '@angular/router';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { faEdit, faTrash, faTimes } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-add-francise',
  templateUrl: './add-francise.component.html',
  styleUrls: ['./add-francise.component.css']
})
export class AddFranciseComponent implements OnInit {

  offerCategory: string = '1';
  name:any;
  email:any;
  mobile:any;
  location:any;
  status:any;
  address:any;
  lat:any;
  lng:any;
  isLoading:boolean;
  getAllAnnouncements:any=[];
  isActive:number = 0;
  franchiselist: any;
  franchise_id =0;
  public deliveryArea: any = [];

  modalRef: BsModalRef;

  constructor(private router: Router, private Api: ApiUserProvider, private modalService: BsModalService) { }

  ngOnInit(): void {
    this.getfranchiseList();
    this.getLocation();
  }

  addFran(template: TemplateRef<any>) {
    this.name = '';
    this.email='';
    this.mobile='';
    this.location='';
    this.address='';
    this.status='';
    this.franchise_id=0;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  isEdit(fra,index,template: TemplateRef<any>){
    this.name = fra.first_name;
    this.email = fra.email;
    this.mobile = fra.mobile;
    this.location = String(fra.served_location);
    this.address = fra.address;
    this.status = fra.is_active;
    this.franchise_id = fra.id;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  updateFranchise() {}
  decline(): void {
    this.modalRef.hide();
  }
  getLocation() {
    this.Api.getLocations().subscribe((data) => {
      this.deliveryArea = data['data'];
    });
  }
  getfranchiseList() {
    this.Api.getDashboardFranchises()
      .subscribe(data => {
        this.franchiselist = data.data;
        console.log(this.franchiselist);
      },
        (error) => {
          alert(error.error.message);
        });

  }
  createFranchise() {
    let details = {
      "name": this.name,
      "email": this.email,
      "mobile": this.mobile,
      "address": this.address,
      "location": this.location,
      "franchise_id": this.franchise_id,
      "status": this.status
    }
   
    this.Api.createFranchise(details)
      .subscribe(data => {
        alert(data.message);
        this.name = '';
        this.email='';
        this.mobile='';
        this.location='';
        this.address='';
        this.status='';
        this.franchise_id=0;
        this.modalRef.hide();
        this.getfranchiseList();

      },
        (error) => {
          alert(error.error.message);
          this.name = null;
          this.email=null;
          this.mobile=null;
          this.location=null;
          this.address=null;
          this.status=null;
          this.franchise_id = null;
          // //console.log('error::::::', error);
        });
  }


}
